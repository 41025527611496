<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="Explosive Safety Fundamentals" size="large" />
    <Paragraph
      ><p>
        Success in the aerospace, air bag component industry, or any other
        industry involving explosives requires integration of the “cardinal
        rule” of explosives safety, which consists of:<br />
        Limiting exposure to a minimum number of personnel, for a minimum amount
        of time, to the minimum amount of explosives consistent with safe and
        efficient operations.
      </p>
      <p>
        This cardinal rule can be implemented by following the “Fundamental
        Principles of Explosives Safety” in all aspects of explosive substances
        or articles manufacturing/assembly. These fundamentals consist of the
        following:
      </p>
      <ul>
        <li>
          Understand the nature of explosives in-process, storage, or
          transportation
        </li>
        <li>Thorough &amp; accurate Process Hazards Analysis (PHA)</li>
        <li>
          Explosives Safety Standards (ESSs) based on lessons learned and PHAs
        </li>
        <li>Rigorous process control</li>
        <li>
          Accountability for explosives safety at all organizational levels.
        </li>
      </ul>
      <p>
        These fundamental principles are reflected in our philosophy and
        approach to Risk Management. SMS offers training courses on the
        fundamentals of explosives safety.
      </p>
      <p>Specific items in the Explosive Fundamentals include the following:</p>
    </Paragraph>
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <TitleComponent title="Understand Your Explosive" />
        <Paragraph
          ><ul>
            <li>Storage: BATF Classification as High or Low Explosive</li>
            <li>
              In-Process Classification: In-process material sensitivity and
              reactivity determination
            </li>
            <li>
              DOT Shipping Classification: Determination of shipping class and
              associated guidelines
            </li>
          </ul>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="Process Hazards Analysis" />
        <Paragraph
          ><ul>
            <li>Identification of potential failure modes</li>
            <li>
              Identification of the cause and effects of the failure mode, as
              well as design safety features and risk assessment
            </li>
            <li>Issuing recommendations to minimize hazards</li>
            <li>
              Publishing PHA report containing a summary and explanation of
              analysis
            </li>
          </ul>
        </Paragraph>
      </div>
    </div>
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <TitleComponent title="Explosives Safety Standards" />
        <Paragraph
          ><ul>
            <li>Processing standard</li>
            <li>Tooling and equipment standards</li>
            <li>Facility standards</li>
            <li>Incident investigation standards</li>
            <li>Training standards</li>
            <li>Procedure standards</li>
          </ul>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="Process Control" />
        <Paragraph
          ><ul>
            <li>Procedure adequacy</li>
            <li>
              Supporting programs (Management of Change, Pre-Start Up Safety
              Reviews)
            </li>
            <li>
              Safety systems, interlocks, monitors, sensors, and associated
              design documentation
            </li>
            <li>Personnel training</li>
          </ul>
        </Paragraph>
      </div>
    </div>
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/4 mb-6"></div>
      <div class="w-full md:w-1/2 mb-6">
        <TitleComponent title="Safety Accountability" />
        <Paragraph
          ><ul>
            <li>Procedures with safety focus</li>
            <li>Management training</li>
            <li>Safety-management relationship</li>
            <li>Economic benefits</li>
          </ul>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/4 mb-6"></div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Explosive Safety Fundamentals",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "The fundamentals of explosives safety include minimizing exposure time, the number exposed, and the quantity."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.about {
}
</style>
